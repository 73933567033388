.main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  /* margin-top: 1%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
  /* height: 10%; */
  /* border: 1px; */
  /* border-color: blue; */
  height: 60px;
}
.submain {
  width: 100%;
  /* border: 0.2px solid black; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ncash_img {
  width: 90px;
  height: 90px;
}
.detaxtxt {
  /* padding-top: 2%; */
  font-size: 25px;
  color: #0d519a;
}
.namecon {
  padding-top: 2%;
  padding-bottom: 1%;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.infocon {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  /* font-size: 19px; */
  /* padding-top: 1%; */
  padding-bottom: 2%;
  flex-direction: column;
}
.midcon {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* padding-top: 2%; */
  flex-direction: row;
}
.midlcon {
  width: 25%;
  /* border: 0.2px solid black; */
  height: 370px;
  border-right: 2px solid black;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 1%;
}
.midrcon {
  width: 75%;
  /* padding-left: 1%; */
  display: flex;
  flex-direction: row;
}
.employee {
  font-size: 18px;
  font-weight: 600;
  /* border-bottom: 2spx solid #0D519A; */
}
.details {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}
.table1 {
  width: 50%;
  text-align: center;
  border-collapse: collapse;
}
/* tr:hover {
    background-color: #e6e6e6;
  } */

.tr1 :hover {
  background-color: #ffffff;
}
.td1 {
  /* color: #4a4a4a; */
  font-weight: normal;
  padding: 1%;
  border: 0;
  /* border-bottom: 1px solid #ececec; */
  text-align: end;
  font-size: 16px;
  font-weight: 600;
}
.td2 {
  /* color: #4a4a4a; */
  font-weight: normal;
  padding: 1%;
  border: 0;
  /* border-bottom: 1px solid #ececec; */
  text-align: start;
  font-size: 0.96rem;
  padding-left: 3%;
}
.paginationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
}

.paginationWrapper > * {
  margin: 0 10px;
}
.footer {
  padding-top: 1%;
  display: flex;
  /* flex-direction: row; */
  width: 90%;
  /* justify-content: flex-end; */
  /* border: 0.2px solid black; */
  padding-bottom: 1%;
}

.btn {
  padding-left: 1%;
  padding-right: 1%;
}
