.main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.header {
  /* margin-top: 1%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  /* height: 10%; */
  /* border: 1px; */
  /* border-color: blue; */
}
.ncash_img {
  width: 90px;
  height: 90px;
}
.detaxtxt {
  /* padding-top: 2%; */
  font-size: 25px;
  color: #0d519a;
}
.subcon {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.leftcon {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.rightcon {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 0.2px solid black; */
  padding-top: 3%;
}
.field {
  padding-top: 5%;
  padding-bottom: 5%;
}
.submain {
  width: 90%;
  /* border: 0.2px solid black; */
  height: 100%;
}
.gettxt {
  color: #0b5298;
  text-align: center;
  font-weight: 500;
  font-size: 35px;
}
.imgcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6%;
}
.backtologin {
  width: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  font-weight: bold;
  gap: 4px;
  /* border: 0.2px solid black; */
}
.btncon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8%;
  padding-bottom: 8%;
  /* border: 0.2px solid black; */
}
.midrcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logincon {
  padding-bottom: 10%;
}
.plzcon {
  padding-top: 1%;
  padding-bottom: 1%;
}
.shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 0.2px solid black; */
  padding: 5%;
  border-radius: 5%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(#efefef, #fff);
  height: 500;
  padding-top: 8%;
}
.btn {
  background-image: linear-gradient(90deg, #8cd4ed, #105099);
}
.login {
  color: #007ee0;
  cursor: pointer;
}
