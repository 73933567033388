.main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  /* margin-top: 1%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.15);
  /* height: 10%; */
  /* border: 1px; */
  /* border-color: blue; */
  height: 60px;
}
.submain {
  width: 100%;
  /* border: 0.2px solid black; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ncash_img {
  width: 90px;
  height: 90px;
}
.detaxtxt {
  /* padding-top: 2%; */
  font-size: 25px;
  color: #0d519a;
}
.namecon {
  padding-top: 3%;
  padding-bottom: 2%;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 23px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.filtercon {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 0.2px solid #eeeeee;
  width: 80%;
  padding-bottom: 2%;
  font-size: 18px;
  padding-top: 1%;
}
.navbar {
  padding-bottom: 1%;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 500;
}
.tablecon {
  width: 90%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.tr2:hover {
  background-color: #e6e6e6;
}
table thead th {
  color: #5c6874;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-left: 1px solid #cdd2d6;
  background: #ececec;
  text-align: center;
  position: sticky;
  top: 0px;
}
table tbody td {
  color: #4a4a4a;
  font-weight: normal;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ececec;
  text-align: center;
  font-size: 0.96rem;
}
.paginationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
}

.paginationWrapper > * {
  margin: 0 10px;
}
.navbarcount {
  background-color: #ececec;
  padding: 3px 10px 0 10px;
  border-radius: 25px;
  margin-left: 3%;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
